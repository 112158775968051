<template>
  <BaseModal
    dataClass="definition-item-modify-modal"
    size="lg"
    v-bind="$attrs"
    v-on="$listeners"
    @show-modal="clear(obj)"
  >
    <template v-slot:main>
      <div data-class="work-list" class="mx-2 my-2">
        <BaseSearchCondition
          :searchCondition="searchCondition"
          :searchConditionInfo="searchConditionInfo"
          @clear="clear(obj)"
          @search="search(obj)"
        >
        </BaseSearchCondition>
        <br />
        <!-- テーブル -->
        <BasePagingTable
          v-model="searchCondition.size"
          id="work-list-table"
          select-mode="single"
          :fields="fields"
          :displayModals="displayModals"
          :items.sync="tableItems"
          :selected-items.sync="selectedItem"
          :columnInfoPage="pagingInfo.page"
          :columnInfoSize="pagingInfo.size"
          :optionsPage="getListOptions(MASTER_CODE.LIST_PAGE, false)"
          :optionsSize="getListOptions(MASTER_CODE.LIST_SIZE, false)"
          :perPage="perPage"
          @size-changed="updateSize"
        />
      </div>
    </template>
    <template v-slot:footer="{ cancel }">
      <!-- 選択ボタン -->
      <BaseButton
        variant="primary"
        icon="check-circle"
        :columnInfo="resultControlInfo.select"
        :disabled="!oneSelected"
        @click="select"
      />
      <!-- 選択解除ボタン -->
      <BaseButton
        v-if="useUnset"
        variant="primary"
        icon="times-circle"
        :columnInfo="resultControlInfo.unselect"
        @click="unselect"
      />
      <!-- 閉じるボタン -->
      <BaseButton
        variant="primary"
        icon="times-circle"
        :columnInfo="resultControlInfo.close"
        @click="cancel"
      />
    </template>
  </BaseModal>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  getCountPerReferencePage,
} from '@/common/Common.js'
import { search, clear } from '@/master/department/DepartmentModal.js'
import { DELETED_KIND } from '@/common/const.js'

export default {
  name: 'departmentModal',

  data() {
    return {
      /**
       * 選択された作業データリスト.
       * @type {Array}
       */
      selectedItem: [],

      /**
       * 作業データリスト.
       * @type {Array}
       */
      tableItems: [],

      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      perPage: getCountPerReferencePage(),
      obj: this,
    }
  },

  props: {
    // <DepartmentModal
    //  departmentKind:
    //   DEPARTMENT_KIND.CARRIER //運送会社
    //   DEPARTMENT_KIND.OWNER //荷主
    // selectedDepartmentKind: {
    //   type: String,
    //   default: '',
    // },
    selectedSearchCondition: {
      type: Object,
      default: () => {},
    },
    displayModals: {
      type: String,
      // 'one'  ->'department-modal'
      // 'two'  ->'department-modal-edit'
      // 'three'->'department-modal-three'
      // 'four' ->'department-modal-four'
      default: 'one',
    },
    useUnset: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    selectedSearchCondition() {
      this.searchCondition.departmentKind = this.selectedSearchCondition.departmentKind
    },
  },

  computed: {
    pagingInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'paging'
      )
    },
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.DEPARTMENT_MODAL_LIST
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        page: searchConditionInfo.page,
        size: searchConditionInfo.size,
        inputComponents: [
          {
            // 部署コード
            type: 'text',
            id: 'departmentCode',
            columnInfo: searchConditionInfo.department_code,
            rules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
          },
          {
            // 部署名
            type: 'text',
            id: 'departmentName',
            columnInfo: searchConditionInfo.department_name,
          },
          {
            // 部署区分
            type: 'select',
            id: 'departmentKind',
            columnInfo: searchConditionInfo.department_kind,
            options: getListOptions(this.MASTER_CODE.DEPARTMENT_KIND),
            onChangeCallback: () => {},
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.DEPARTMENT_MODAL_LIST
      )('result_ctrl_info')
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.DEPARTMENT_MODAL_LIST
      )(this.DOMAIN_NAME.FIELDS)
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    oneSelected() {
      return this.selectedItem.length === 1
    },
  },

  methods: {
    getListOptions,
    search,
    clear,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      let defaultKind = null
      if (this.selectedSearchCondition.departmentKind != null) {
        defaultKind = this.selectedSearchCondition.departmentKind
      }
      return {
        departmentCode: null,
        departmentName: null,
        departmentKind: defaultKind,
        deleted: DELETED_KIND.NOT_DELETED,
        size: getSearchMaxCount(),
        screenId: this.SCREEN_ID.DEPARTMENT_MODAL_LIST,
        domainName: 'result_fields',
      }
    },

    select() {
      if (this.displayModals == 'one') {
        this.$bvModal.hide('department-modal')
        this.$bvModal.hide('carrier-modal')
      } else if (this.displayModals == 'two') {
        this.$bvModal.hide('department-modal-edit')
        this.$bvModal.hide('carrier-modal-edit')
      } else if (this.displayModals == 'three') {
        this.$bvModal.hide('department-modal-three')
        this.$bvModal.hide('carrier-modal-three')
      } else if (this.displayModals == 'four') {
        this.$bvModal.hide('department-modal-four')
        this.$bvModal.hide('carrier-modal-four')
      }
      this.$emit(
        'after-close-set',
        this.selectedItem[0].department_id,
        this.selectedItem[0].department_code,
        this.selectedItem[0].department_name
      )
    },

    unselect() {
      if (this.displayModals == 'one') {
        this.$bvModal.hide('department-modal')
        this.$bvModal.hide('carrier-modal')
      } else if (this.displayModals == 'two') {
        this.$bvModal.hide('department-modal-edit')
        this.$bvModal.hide('carrier-modal-edit')
      } else if (this.displayModals == 'three') {
        this.$bvModal.hide('department-modal-three')
        this.$bvModal.hide('carrier-modal-three')
      } else if (this.displayModals == 'four') {
        this.$bvModal.hide('department-modal-four')
        this.$bvModal.hide('carrier-modal-four')
      }
      this.$emit('after-close-unset')
    },

    updateSize: function (size) {
      this.searchCondition.size = size
    },
  },
}
</script>

<style scoped></style>
